.titleLayoutPI{
    font-weight: bold !important;
    background-color: #042354;
    padding: 15px;
    width:100%;
    height:fit-content;
}

.loginImg{
    width:auto;
    height: 34px;
}
.loginCaps{
    margin-left: 11px;
    width:auto;
    height: 20px;
}

.pucpPhoto{
    width: 100%;
    height: 30dvh;
    object-fit: cover;
    object-position: center;
}

.mapDescription {
    padding: 15px;
}

/* Mapa CSS */
/* VARIABLES */
:root {--primary-color: #042354; --secondary-color: #FAD634; --btn-color-text: #fff; --btn-color2-text: #444444; --highlight-color: rgb(0, 78, 168);}

@media only screen and (max-width: 768px) {
  img.background {width: auto !important; height: 100vh; }
  .building {width: auto !important; opacity: 1 !important;}
}

/*LOADING*/
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}

@-o-keyframes spin {
    100% { -o-transform: rotate(360deg); }
}

@keyframes spin {
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loading-grow {
    0% { stroke-dasharray: 0 100px; }
    100% { stroke-dasharray: 100px 100px; }
}

.loading { position: fixed; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0, 0, 0, 0.8); z-index: 200; opacity: 0; pointer-events: none; }
.loading.active { opacity: 1; pointer-events: all; backdrop-filter: blur(5px); }
.loading .loading-container { text-align: center; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); }
.loading .loading-container svg { width: 100px; -webkit-animation: spin 1.5s linear infinite; -moz-animation: spin 1.5s linear infinite; -o-animation: spin 1.5s linear infinite; animation: spin 1.5s linear infinite; }
.loading .loading-container svg path { stroke-width: 2; }
.loading .loading-container svg path:first-child { stroke: transparent; }
.loading .loading-container svg path:last-child { stroke: var(--primary-color); animation: loading-grow 1s alternate infinite; }
.loading .loading-container .progress { position: fixed; top: calc(50% - 2px); font-size: 0.85em; display: block; width: 100%; text-align: center; transform: translateY(-50%); -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); -o-transform: translateY(-50%); }


/* GENERAL */
* {font-family: 'Montserrat', sans-serif}
html, body, header, main, section, footer, div, span, table, thead, tbody, tr, td, th, img, label, input, select, textarea, p, h1, h2, h3, h4, h5, h6, form, ul, ol, li, a, svg {margin: 0; padding: 0; box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -webkit-box-sizing: border-box; -khtml-box-sizing: border-box;}
html, body {width: 100%; height: 100%; max-width: 100%; min-height: 100%;}
img.background {display: block; width: 100%; pointer-events: none;position: relative;z-index: 10;}
body {cursor: grab;}

.content#mapa {position: relative; overflow: auto; user-select: none;}
.scroll-hidden {-ms-overflow-style: none; scrollbar-width: none;}
.scroll-hidden::-webkit-scrollbar {display: none;}
.disabled { opacity: 0.3; pointer-events: none; color: #000 !important; }

/* MAPA */
.building {opacity: 0; position: absolute; pointer-events: none; top: 0; left: 0; width:100%; height: 100%;z-index: 100;}
.building path {pointer-events: all; ;cursor: pointer;position: relative;z-index: 2000;}
.build:hover {opacity: 0.2;}
.building.svg.hover {opacity: 1;transition: 0.3s;}
.building.svg:not(.hover) {opacity: 0;}

/* ZOOM */
.zoom {position: fixed; bottom: 25px; right: 25px; display: flex; flex-direction: column; padding: 10px; border: 1px solid; border-radius: 10px; gap: 5px; background-color: rgba(255, 255, 255, 0.5);z-index: 500;}

/*ICON*/
a.icon { position: relative; }
.icon.svg { display: inline-block; position: absolute; top: 3px;}
.icon:not(.original-color) svg * {fill: var(--text-color);}

/*BUTTONS*/
.button { position: relative; margin-top: 5px; display: inline-flex; text-align: center; font-weight: 400; overflow: hidden; background-color: var(--primary-color); color: var(--btn-color-text); border-radius: 8px; border: 1px solid var(--primary-color); justify-content: center; align-items: center; cursor: pointer;}
.button:after { content: ''; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0; border-radius: 5px; }
.btn-small { max-width: 117px; width: 40px; height: 40px; font-size: 2rem; font-weight: bold;}
.btn-medium {padding: 7px 15px; border-radius: 37px;}
.btn-medium, .btn-medium > label {font-size: 0.75rem !important; font-weight: bold;}
.btn-primary-color { background-color: var(--primary-color); color: var(--btn-color-text) !important; border-color: var(--primary-color); }
.btn-secondary-color { background-color: var(--secondary-color); color: var(--btn-color2-text) !important; border-color: var(--secondary-color); }
.btn-icon {display: inline-flex; align-items: center; justify-content: center;}
.btn-icon svg {display: block;}

/*POPUPS*/
.popup {width: 100%; height: 100%; pointer-events: none; display: flex; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; background: rgba(0, 0, 0, 0.2); z-index: 200; opacity: 0; transition: opacity 0.2s ease-in-out;}
.popup .popup-box::-webkit-scrollbar {display: none;}
.popup .popup-box {-ms-overflow-style: none; scrollbar-width: none;}
.popup .popup-box {flex-grow: 1; position: relative; background-color: rgba(255, 255, 255); overflow-y: auto; border-radius: 16px; padding: 37px; margin: 20px; max-width: 575px; max-height: calc(100% - 60px); backdrop-filter: blur(10px); box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1); transform: scale(0.97); transition: transform 0.2s ease-in-out;}
.popup .popup-box.small-horizontal-padding {padding-left: 10px; padding-right: 10px;}
.popup .popup-box.big-vertical-padding {padding-top: 60px; padding-bottom: 50px;}
.popup .popup-box.big-horizontal-padding {padding-left: 30px; padding-right: 30px;}
.popup .popup-box .content .body .image-container, .popup .popup-box .content .labs-body .building-info .image-container {height: 240px;}
.popup .popup-box .content .body img, .popup .popup-box .content .labs-body .building-info img { width: 100%; border-radius: 18px; height: 100%; object-fit: cover;}
.popup .popup-box .content .body .description {position: relative; display: flex; margin-top: 25px;}
.popup .popup-box .content .body .description .popup-title {color: var(--primary-color);}
/* .popup .popup-box .content .actions {display: flex; justify-content: center; flex-direction: row-reverse; flex-wrap: wrap; margin-top: 25px;} */
.popup .popup-box .content .description .actions {position: absolute; right: 0px; bottom: 0px;}
.popup .popup-box .close {width: 30px; height: 30px; display: block; cursor: pointer; position: absolute; top: 25px; right: 25px; background-image: url(../../../public/mapasrc/img/icons/ic_close.svg); background-position: center; border-radius: 50%; background-size: 34px; background-repeat: no-repeat; background-color: #fff; z-index: 1;}
.popup.active {pointer-events: all; opacity: 1 !important;}
.popup.active .popup-box {transform: scale(1);}

.popup:not(.popup-labs) .body {
    display: block;
}

.popup:not(.popup-labs) .labs-body {
    display: none;
}

.popup.popup-labs .popup-box {
	-ms-overflow-style: auto;
	scrollbar-width: auto;
}

.popup.popup-labs .body {
    display: none;
}

.popup.popup-labs .labs-body {
    display: block;
}

.popup.popup-labs .popup-box {
    background-color: #e8e8e8;
}

.popup .building-info {
	display: flex;
	flex-wrap: wrap;
    gap: 10px 25px;
    align-items: center;
}

.popup .lab {
	border-radius: 15px;
	overflow: hidden;
}

.popup .lab-content {
    padding: 10px;
}

.popup .labs-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
}

.popup .lab {
    background: #fff;
}

#buildingImage {
    width: 250px;
}

@media screen and (max-width: 620px){
    #buildingImage {
        width: 100%;
    }
}

.popup .building-description {
    flex: 1;
}

.popup .lab-content .lab-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--highlight-color);
}

.popup .lab-info {
	display: flex;
	flex-wrap: wrap;
    gap: 10px 25px;
    margin-top: 10px;
}

.popup .lab-info-item {
	display: flex;
	gap: 5px;
    align-items: center;
}

.popup .lab-floor {
	min-width: 150px;
}

.popup .lab-responsible {
	min-width: fit-content;
}

.popup .lab-links {
    display: flex;
}

.popup .lab-links .button {
	flex: 1;
	border-radius: 0;
    background-color: var(--highlight-color);
    padding: 5px;
}

.popup .lab-links .button .icon.mail {
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
}

.cls-2{fill:#40b9e4 !important}

/*TOOLTIPS*/
/* .tooltip-container { position: relative; }
.tooltip { max-width: inherit; height: auto; z-index: 10; position: fixed; box-shadow: 0 3px 5px rgba(0,0,0,0.15); font-size: 0.7rem; border-radius: 6px; font-weight: 400; opacity: 0; pointer-events: none; background-color: white;}
.tooltip-container:hover .tooltip { opacity: 1; pointer-events: all; }
.tooltip-container .tooltip .tooltip-content { width: 100%; border-radius: 6px; position: relative; padding: 12px 15px; text-align: left; white-space: pre-line; }
.tooltip-container .tooltip .tooltip-content::after { content: ''; display: block; width: 10px; height: 10px; position: absolute; top: -4px; left: 9px; -webkit-transform: rotate(45deg) skew(15deg, 15deg); -moz-transform: rotate(45deg) skew(15deg, 15deg); -ms-transform: rotate(45deg) skew(15deg, 15deg); -o-transform: rotate(45deg) skew(15deg, 15deg); transform: rotate(45deg) skew(15deg, 15deg); }
.tooltip-container .tooltip.special { width: 182px; }
.tooltip-container .tooltip.special .tooltip-content { text-align: center; padding: 8px; word-break: break-word; }
.tooltip-container .tooltip.special .tooltip-content::after { bottom: -4px; left: 47px; top: initial; } */