.instructionsPreScan {
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    background-color: #F7F7FC;
    height: 70px;
    min-width: 100%;
}

.button-back{
    position: fixed;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 110px;
    padding: 12px 30px;
    bottom: 0;
}

.button-return{
    color: #004EA8;
    border: 1.5px solid #004EA8;
    border-radius: 12px;
}