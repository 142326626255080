.titleLayoutPI{
    font-weight: bold !important;
    background: #F8F8F8;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
    width:100%;
    height:50px;
}

.iconTitlePI{
    vertical-align: Bottom;
    cursor: pointer;
    margin-right: 2px;
}

.iconTextPI{
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.titleIconPI{
    margin-left: 5px;
    color: #004EA8;
}

.title-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}