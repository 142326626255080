.unscheduled-container {
    padding: 15px 0px 5px;
    display: flex;
}

.status-no-activity {
    justify-content: center;
    border: 1px solid #004EA8;
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 4px;
    background: #E6EDF6;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    color: #003777;
    margin-bottom: 10px;
    padding: 8px;
}

.status-other-activity {
    justify-content: center;
    background: #FCEFE6;
    border: 1px solid #DE5C00;
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    color: #9E4100;
    margin-bottom: 15px;
    padding: 8px;
}

.siteDetails {
    align-items: center;
    margin-bottom: 90px;
    padding-bottom: 20px;
}

.imgContainer {
    position: relative;
    text-align: center;
}

.overlayGradient {
    height: 20vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(4, 35, 84, 0) 48.3%, #042354 100%);
}

.textContainer {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.infoIcon {
    position: absolute;
    bottom: 8px;
    right: 16px;
    margin: 15px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.infoIcon img {
    width: 22px;
    height: 22px;
}

.siteImg {
    height: 20vh;
    width: 100%;
}

.timerTextNumber {
    text-align: center;
    justify-content: center;
    /* margin-top: 50px; */
}

.timerTextLegend {
    text-align: center;
    justify-content: center;
    /* margin-bottom: 50px; */
}

.text {
    text-align: left;
    justify-content: left;
    margin: 15px;
}

.checkIn {
    margin-top: 10px;
    margin-bottom: 20px;
}

.space {
    height: 30px;
    width: 30px;
}

.warning {
    width: 380px;
    text-align: center;
}

#countdown {
    position: absolute;
    top: 0;
    left: 0;
    height: 140px;
    width: 140px;
    text-align: center;
}

#countdown svg {
    top: 0;
    right: 0;
    width: 140px;
    height: 140px;
    transform: rotate(-90deg);
}

#countdown svg circle {
    stroke-dasharray: 412px;
    stroke-dashoffset: var(--time-value);
    stroke-linecap: round;
    stroke-width: 4px;
    stroke: #004EA8;
    fill: none;
    transition: 0.3s;
    /* animation: countdown var(--time-value) linear infinite forwards; */
}

@keyframes countdown {
    from {
        stroke-dashoffset: 412px;
    }

    to {
        stroke-dashoffset: 0px;
    }
}

.infoIcon:hover ~ .tooltiptext {
    visibility: visible;
}

.infoIcon:active ~ .tooltiptext {
    visibility: visible;
}

.imgContainer .tooltiptext {
    background: #004EA8;
    text-align: center;
    left: 20px;
    bottom: 50px;
    width: 90vw;
    max-width: 90vw;
    display: flex;
    align-items: center;
    /* -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

.tooltiptext-content {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-height: 72px;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personalizedDivider{
    padding-top: 5px;
    padding-bottom: 5px;
    color:#042354;
}

.MuiTypography-h5{
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 600;
}

.text-overflow-locDet {
    text-align: left;
    justify-content: left;
    margin: 15px;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.container-down{
    position: absolute;
    bottom: 0;
    margin-bottom: 130px;
    align-content: flex-end;
    justify-content: center;
    margin-top: 10px;
}

.button-attendance{
    float: right;
    margin: 0px 36px 20px 0px;
}

.button-list-attendance{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 108px;
    width: 108px;
    border-radius: 100px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
}

.buton-list-numbers{
    position: relative;
    padding: 2px;
    font-size: 24px;
}

.buton-list-label{
    position: relative;
    padding: 2px;
    font-size: 12px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; 
}

.see-attendance{
    position: fixed;
    top: 48%;
    left: 65%;
    height: 30%;
    width: 50%;
}

.attendance-info {
    height:64px;
    width: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
}