.activityCardPI {
    align-items: center;
    margin-top: 15px;
    margin-bottom: 23px;
    display: flex;
    justify-content: left; 
    width: 350px;
    height: 136px;
    stroke-width: 0.5;
    stroke: "#A5B0C1";
    text-align: center;
    border-radius: 15px;
}

.activityCardPIReport{
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: left; 
    width: 350px;
    height: 100px;
    stroke-width: 0.5;
    stroke: "#A5B0C1";
    text-align: center;
    border-radius: 15px;
}

.cardContent {
    width: 100%;
    padding-top: 24px;
}

.shift {
    border: 1.5px solid #FF9929;
    border-radius: 8px;
    padding: 2px;
    margin: 2px;
    width: 80px;
}

.shift-text {
    text-align: center;
}

.text-overflow {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-title-container {
    position: relative;
    overflow: visible;
}

.text-container {
    position: relative;
    overflow: visible;
}

.max-width-full {
    max-width: 100% !important;
}
    
.tooltiptext {
    visibility: hidden;
    width: fit-content;
    max-width: 80vw;
    background-color: #585757;
    color: white;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    white-space: normal;
    font-size: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 0px;
}

.text-container:hover .tooltiptext {
    visibility: visible;
}

.text-container:active .tooltiptext {
    visibility: visible;
}

.text-title-container:hover .tooltiptext {
    visibility: visible;
}

.text-title-container:active .tooltiptext {
    visibility: visible;
}

.label-attended{
    position: relative;
    align-content: flex-end;
    justify-content: center;
    margin-top: 10px;
    color: #8BB733;
    border: 2px solid #8BB733;
    border-radius: 8px;
    max-width: 100%;
    padding: 3px 5px;
}

.label-absent{
    position: relative;
    align-content: flex-end;
    justify-content: center;
    margin-top: 10px;
    color: #E23008;
    border: 2px solid #E23008;
    border-radius: 8px;
    max-width: 100%;
    padding: 3px 5px;
}