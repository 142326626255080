@import './AttendanceStudentScan.css';

.TI-div {
    border-radius: 11px;
    margin: 10px 30px;
    display: flex
}

.TI-image{
    width: 85px;
    height: 115px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
}

.TI-image-data{
    display: grid;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
}

.button-down{
    position: relative;
    align-content: flex-end;
    justify-content: center;
    margin-top: 10px;
}

.activity-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 20px;
    margin-bottom: 0px;
    padding: 10px 15px;
    color: white;
    border-radius: 8px;
}

.has-activity {
    background: #042354;
}

.other-activity {
    background: #DC7E16;
}

.no-activity {
    background: #C00101;
}

.icon-status{
    margin-right:8px;
}

.group-buttons{
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 110px;
    bottom: 0;
}

.grid-student{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
}

.text-doi {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: rgba(0, 78, 168, 1);
}

.text-name {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.text-code {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.box-message {
    min-height: 40px;
    border-radius: 4px;
}

.box-message-info {
    background: linear-gradient(0deg, #E6EDF6, #E6EDF6);
    border: 1px solid rgba(0, 78, 168, 1);
}

.box-message-success {
    background: linear-gradient(0deg, #EAF2EB, #EAF2EB);
    border: 1px solid rgba(46, 125, 50, 1)
}

.box-message-error {
    background: linear-gradient(0deg, #FBEAEA, #FBEAEA);
    border: 1px solid rgba(211, 47, 47, 1)
}

.message-icon {
    font-size: 100%;
    margin-right: 2px;
}

.box-text {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
