.waitingRoom {
    text-align: center;
    padding: 1px;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
}

.instructionsDiv {
    text-align: center;
    margin-top: 40px;
    justify-content: center;
    margin-bottom: 50px;
}

.instruction {
    text-align: left;
    line-height: 24px;
}

.instructionsPreScan {
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    background-color: #F7F7FC;
    height: 70px;
    min-width: 100%;
}

.qrScan {
    width: 100%;
}

.text {
    width: 100%;
}
