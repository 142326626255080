
.bottomNavigationPaper{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px 15px 15px;
    height:76px;
    background-color: white;
    box-shadow: none;
    border-radius: 12px;
}

.bottomNavigationCustom{
    background-color: white;
    position: relative;
}

.navigationIcon{
    width:20px;
}

.gridMuddleButton{
    margin-bottom: 50px;
    border: 3px solid #F8F8F8;
}

.gridMuddleButton .MuiGrid-root{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.middleButton{
    background-color: #004EA8;
    border-radius: 100px;
    width: 55px;
    height: 55px;
}

.navigationIconQR{
    background-color: #004EA8;
    margin-left: 10px;
}

.disabledNavigationIcon{
    transition: 0;
    width:20px;
    filter: invert(47%) sepia(25%) saturate(438%) hue-rotate(176deg) brightness(92%) contrast(85%);
    /* filter: grayscale(1); */
}

.bottomNavigationCustom button img {
    transition: 0.5s;
    position: absolute;
    z-index: 9999;
}

.MuiBottomNavigationAction-root.Mui-selected{
    color: #004EA8;
}

.bottomNavigationCustom button span:not(.MuiTouchRipple-root) {
    transition: 0.5s;
    transform: translateY(25px);
    position: absolute;
    z-index: 9998;
}

.bottomNavigationCustom button.Mui-selected span:not(.MuiTouchRipple-root) {
    opacity: 1;
}

.MuiBottomNavigationAction-root{
    padding: 0px !important;
}