.circleButton{
    border-radius: 100px;
    width: 100px;
    height: 100px;
    flex-direction: column;
    border-color: #A5B0C1 !important;
    color: #004EA8;
    margin-bottom: 10px;
    background-color: #FFFFFF; 
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 84px;
}

.circleButton:hover{
    background-color: #FFFFFF !important;
}

.circleButton img {
    margin-bottom: 5px;
}