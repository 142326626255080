@import "../ViewsCommonStyles.css";

.select{
    display: flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    align-items: center;
}

.text-select{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
}

.menuitem{
    display: flex;
    max-width: 350px;
}

.text-menuitem{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
}