.onBoardingLayout {
    margin-top: 60px;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    height: 1vh;
    background: white;
    opacity: 1;
    animation: appear 6s;
}

.activityOnBoarding {
    margin-top: 65px;
}

.gridContainer {
    margin-bottom: 6vh;
}

.activityNameOnBoarding {
    margin-top: 9px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


/*--------------------
Stage
--------------------*/
.onboard {
    position: relative;
    width: 350px;
    height: 500px;
    background: transparent;
    align-self: center;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

h1 {
    padding: 30px;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    color: #1c222b;
}

h1 strong {
    background: #fa6b5b;
    color: #042354;
    display: inline-block;
    border-radius: 30px;
    padding: 8px;
    font-size: 83%;
    line-height: 1;
    position: relative;
    top: -2px;
    left: 2px;
}

.slick-track {
    transition: all .5s cubic-bezier(.93, .01, .15, .97);
    ;
}

.slick-slider {
    margin-top: 100px;
    margin-bottom: 40px;
}

.item {
    width: 300px;
    text-align: center;
    color: #eee;
    outline: none;
    padding: 0px 30px 0;
}

.item .ico {
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item .ico svg {
    width: 130px;
    height: 130px;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.item .cta {
    background: linear-gradient(-5deg, #223b63, #042354);
    border-radius: 30px;
    font-size: 18px;
    display: block;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    padding: 14px 10px;
    margin: 35px 0 0;
    cursor: pointer;
}

.item .cta:hover {
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, .3);
}

.item .cta:focus {
    box-shadow: inset 0 1px 7px rgba(0, 0, 0, .4);
}

.imageOnBoarding {
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
}

/*--------------------
Animation
---------------------*/

.from-right .ico,
.from-right .tit,
.from-right .text,
.from-right .cta {
    opacity: 0;
    transform: translate(200px);
}

.from-right .ico {
    transform: translate(200px) rotate(45deg);
}

.from-left .ico,
.from-left .tit,
.from-left .text,
.from-left .cta {
    opacity: 0;
    transform: translate(-200px);
}

.from-left .ico {
    transform: translate(-200px) rotate(-45deg);
}

.ready .ico,
.ready .tit,
.ready .text,
.ready .cta {
    transform: translate(0) rotate(0);
    opacity: 1;
    transition: all .5s cubic-bezier(.93, .01, .15, .97) .5s;
}

.ready .tit {
    transition-delay: .6s;
}

.ready .text {
    transition-delay: .7s;
}

.ready .cta {
    transition: all .5s cubic-bezier(.93, .01, .15, .97) .8s, box-shadow .3s ease 0s;
}


/*--------------------
Slick Dots
---------------------*/

.slick-dots a {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
}

.slick-dots span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #042354;
}

.slick-dots circle {
    fill: none;
    stroke: #042354;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-dasharray: 39 39;
    stroke-dashoffset: 39;
    transition: all .9s ease-in-out;
    transition: stroke-dashoffset 0.5s cubic-bezier(.93, .01, .15, .97);
    ;
}

.slick-dots .slick-active circle {
    stroke-dashoffset: 0;
}