.alertTitlePI {
    text-align: center;
    font-size: 18px;    
    font-family: "Roboto";
    font-weight: 600;
}

.alertContentPI {
    text-align: justify;
}

.alertOpButtonPI {
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 600;
}

.dialogRounded {
    border-radius: 12px;
}

.buttonAlertOption {
    border-radius: 8px;
}

.dialogActionsStyle {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
}