.loginContainer {
    align-items: 'center';
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #042354 0%, #090913 100%);
    padding-bottom: 150px;
}

.imageLoginContainer {
    width: 100%;
    height: 20%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.imageLogoContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}

.textInitial {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 5px;
    text-align: center;
    font-weight:400;
}


.buttonGoogleEnter {
    font-size: 0.90rem;
    margin-top: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    width:320px;
    height:45px;
    border-radius: 15px;
}


.loginImg{
    width:81px;
    height: 77px;
}
.loginCaps{
    margin-left: 11px;
    width:193px;
    height: 82px;
}

.form-login {
    background-color: white;
    margin: 50px;
    padding: 25px;
    border-radius: 15px;
}
.scrollable .MuiTabs-flexContainer {
    overflow-x: auto;
}