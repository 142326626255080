@import './AlertOptions.css';

.alert-dialog{
    position: absolute;
    margin: 32px 16px 90px 16px;
}

.alertRowTitlePI {
    text-align: center;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 600;
    padding: 10px 24px;
}

.dialogRowActionsStyle {
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 16px 10px 16px;
}

.alertRowOpButtonPI {
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 600;
    margin: 2px 0px 8px 0px;
    padding: 9px;
}

