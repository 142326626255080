.containerCardsView {
    margin-top: 16px;
    margin-bottom: 100px;
    padding-bottom: 10px;
    width: 100%;
}

.containerView {
    margin-top: 16px;
    margin-bottom: 100px;
    padding-bottom: 10px;
}

.iconStyle {
    padding-bottom: 5px;
    vertical-align: Bottom;
}

.titlesViews {
    font-style: italic;
    font-weight: bold;
}

.noData {
    height: 60vh;
    align-items: center;
    align-content: center;
    text-align: center;
    display:row;
    justify-content:center;
}

.noActivitiesImg {
    padding-top: 30%;
    margin-bottom: 100px;
    width: 100%;
}

.containerButtonIcon{
    margin-top: 24px;
}

.activityButtonContainer {
    margin-top: 24px;
    width: 350px;
}

.activity-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}