.assistanceCardPI {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    width: 325px;
    height: 105px;
    stroke-width: 0.5;
    stroke: "#A5B0C1";
    text-align: center;
    border-radius: 15px;

}

.assistanceCardMediaPI {
    width: 115px;
    height: 104px;
} 

.assistanceCardColumnPI {
    display: flex;
}

.assistanceCardContentPI {
    padding: 10px !important;
    width: calc(100% - 115px);
}

.text-overflow-ass {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-title-container {
    position: relative;
    overflow: visible;
}

.text-container {
    position: relative;
    overflow: visible;
}

.tooltiptext-ass {
    visibility: hidden;
    width: fit-content;
    max-width: 48vw;
    background-color: #585757;
    color: white;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    white-space: normal;
    font-size: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 18px;
    left: 0px;
}

.text-container:hover .tooltiptext-ass {
    visibility: visible;
}

.text-container:active .tooltiptext-ass {
    visibility: visible;
}

.text-title-container:hover .tooltiptext-ass {
    visibility: visible;
}

.text-title-container:active .tooltiptext-ass {
    visibility: visible;
}