.profile-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.TI-div {
    background: 0.5px solid #042354;
    border-radius: 11px;
    margin: 30px 30px 20px 30px;
    display: flex
}

.TI-image{
    width: 85px;
    height: 115px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
}

.TI-image-data{
    display: grid;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
}

.barcode-div {
    background: #FFFFFF;
    border: 1px solid rgba(4, 35, 84, 0.2);
    height: 96px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 10px 30px 20px 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.barcode-optionOn{
    background-color: #042354 !important;
    color: #FFF !important;
}

.barcode-optionOff{
    background-color: #FFF !important;
    color: #042354 !important;
}

.barcode-barqr{
    display: flex;
    justify-content: center;
    padding: 0px;
}

.switch-control{
    font-size: 10px;
}

.barcode-togglebtn{
    width: 60px;
    height: 100x;
    display: flex;
    justify-content: center;
}

.barcode-switch-container{
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
}

.barcode-switch{
    width: 80px;
}

.barcode-label{
    position: absolute;
    align-content: center;
    padding-top: 1px;
    font-size: 8px;
}

.barcode-label-left{
    margin-left: 12px;
}

.barcode-label-right{
    margin-right: 7px;
}

.MuiSwitch-switchBase{ 
    padding-top: 10 !important;
}

.MuiSwitch-switchBase.Mui-checked{
    transform: translateX(45px) !important;
}

.MuiSwitch-thumb{
    width: 64 !important;
    height: 32;
    background-color: #042354;
}

.MuiSwitch-track{
    height: 15px;
    border-radius: 30 / 2;
    background-color: #042354;
}

.options-container {
    margin-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 100px;
}

.profile-option {
    justify-content: space-between;
    margin-top: 15px;
    stroke-width: 0.5;
    stroke: "#A5B0C1";
    background: #FFFFFF;
    border: 1px solid rgba(4, 35, 84, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 64px;
}

.option-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.disabled-icon {
    filter: invert(47%) sepia(25%) saturate(438%) hue-rotate(176deg) brightness(92%) contrast(85%);
}

.selected-icon {
    filter: invert(91%) sepia(18%) saturate(51%) hue-rotate(206deg) brightness(104%) contrast(97%);
}