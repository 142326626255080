.bigCircleButton{
    border-radius: 150px;
    width: 170px;
    height: 170px;
    flex-direction: column;
    border-color: #A5B0C1 !important;
    color: #004EA8;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 84px;
}

.bigCircleButton:hover{
    background-color: #FFFFFF !important;
}

.button-center-up{
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 75px;
}

.button-center-down{
    display: flex;
    position: absolute;
    margin-top: 215px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-left-up{
    display: flex;
    position: absolute;
    left: 0;
    margin-left: 16px;
}

.button-right-up{
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 16px;
}

.button-center{
    display: flex;
    position: absolute;
    margin-top: 140px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button-left-down{
    display: flex;
    position: absolute;
    margin-left: 16px;
    margin-top: 180px;
}

.button-right-down{
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 16px;
    margin-top: 180px;
}