.locationDetailCard {
    min-width: 200px;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 8px;
}

.infoLocationIcon img {
    width: 22px;
    height: 22px;
}

.infoLocationIcon:hover ~ .tooltiptextLocation {
    visibility: visible;
}

.infoLocationIcon:active ~ .tooltiptextLocation {
    visibility: visible;
}

.cardLocationContent {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px !important;
}

.containerLocation {
    flex-wrap: nowrap
}

.imgContainer .tooltiptextLocation {
    background: #004EA8;
    text-align: center;
    left: 20px;
    bottom: 50px;
    width: 90vw;
    max-width: 90vw;
    display: flex;
    align-items: center;
    /* -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

.tooltiptextLocation {
    visibility: hidden;
    width: fit-content;
    max-width: 84vw;
    background-color: #004EA8;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    white-space: normal;
    font-size: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 180px;
    left: 30px;
}

.tooltiptextLocation-content {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-height: 72px;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timer {
    height: 100px;
    width: 100px;
    /* border: 4px solid #FF9929;
    border-radius: 50%; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    /*margin-bottom: 30px;*/
    position: relative;
}

.label-weight-location{
    font-weight: 500;
    align-items: center;
}

.image-site{
    display: flex;
    position: absolute;
    height: 78px;
    width: 78px;
    margin-left: 3px;
}

.image-site img{
    border-radius: 50%;
}

.image-arrow{
    display: flex;
}