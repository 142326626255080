.container{
    margin-top: 16px;
    margin-bottom: 100px;
    padding-bottom: 10px;
}

.toggle-group {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 35px;
    border-radius: 8px;
    overflow: auto;
}

.data-container {
    align-items: center;
    justify-content: center;
}

.container-students{
    height: 480px;
    overflow-y: scroll;
}

.grid-student{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
}

.text-even{
    background: #F7F7FC;
    position: relative;
}

.text-student{
    position: relative;
    font-size: 12px;
    color: #004EA8;
    max-width: 170px;
    padding: 15px;
}

.button-student{
    display: flex;
    position: relative;
    align-items: center;
}

.button-student-flex-end{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
}

.button-assistance{
    color: #042354;
    border-color: #042354 !important;
    width: 90px;
    height: 35px;
    margin-right: 10px;
}

.button-fault{
    color: #E23008;
    border-color: #E23008 !important;
    width: 30px;
    height: 35px;
    padding: 10px;
    margin-right: 15px;
}

.button-cancel{
    color: #DC7E16;
    border-color: #DC7E16 !important;
    width: 30px;
    height: 35px;
    padding: 10px;
    margin-right: 15px;
}

.text-fault{
    color: #E23008;
}

.button-down-report{
    position: relative;
    align-content: flex-end;
    justify-content: center;
    margin-top: 20px;
}

.pagination-div {
    margin-top: 20px;
}

.container-search{
    display: flex;
    flex-direction: column;
    margin: 0px 15px 10px 15px;
}

.text-search{
    position: relative;
    font-size: 14px;
    max-width: 170px;
    padding-bottom: 5px;
}

.enter-label .MuiInputBase-root{
    color: #A5B0C1 !important;
}

.group-buttons-report{
    position: relative;
    align-items: flex-end;
    justify-content: center;
}

.text-doi {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: rgba(0, 78, 168, 1);
}

.text-name {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.text-code {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.tab-selected {
    width: Hug (118px);
    height: Hug (27px);
    padding: 4px 16px 4px 16px;
    border-radius: 100px;
    background-color: #004EA8;
}

.tab-label {
    font-size: 16px;
}