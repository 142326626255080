.container-date-picker {
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.1);
    border-radius: 16px;
}

.selected-button {
    background: #004EA8;
    border-radius: 16px;
    width: 24px;
    height: 7.25px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.not-selected-button {
    width: 8px;
    height: 8px;
    background: #BBBBBB;
    border-radius: 16px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}