@import "../ViewsCommonStyles.css";

.toggle-group {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 35px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.toggle-option{
    width: 150px;
    height: 35px;
    border-radius: 8px;
}

.titleAttendanceResults {
    margin-top: 15px;

}

.cardContentAttendance {
    align-items: center;
    justify-content: center;
}

.tabsAttendance {
    width: 100%;
}

.tabAttendance {
    width: 50%;
}

.data-container {
    align-items: center;
    justify-content: center;
}

.barcode-optionOn{
    background-color: #042354 !important;
    color: #FFF !important;
}

.barcode-optionOff{
    background-color: #FFF !important;
    color: #042354 !important;
}