.capacityCardPI{
    align-items: center;
    margin-top: 20px;
    display: flex;
    width: 325px;
    height: 105px;
    stroke-width: 0.5;
    stroke: "#A5B0C1";
    text-align: center;
    border-radius: 15px;
}

.capacityMediaPI{
    width: 127px;
    height: 104px;
}

.capacityBoxContentPI{
    display: flex;
    flex-direction: column;
    width: calc(100% - 127px);
}

.capacityContentPI{
    flex: 1 0 auto;
}

.capacityTitlePI{
    /*font-size: 1.00rem;
    font-weight: 600;*/
    margin-top: 40px;
    margin-bottom: 10px;
}

.capacityBigTitlePI{
    font-size: 2rem;
    font-weight: 600;
    text-align: center; 
}

.capacityLabelTitlePi{
    font-size: 0.80rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px; 
}

.capacitySubtitlePI{
    font-size: 0.80rem;
    font-weight: 600;
}

.text-overflow-cap{
    text-align: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 10px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.text-container-cap {
    position: relative;
    overflow: visible;
}

.tooltiptext-cap {
    visibility: hidden;
    width: fit-content;
    max-width: 48vw;
    background-color: #585757;
    color: white;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    white-space: normal;
    font-size: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 18px;
    left: 0px;
}

.text-container-cap:hover .tooltiptext-cap {
    visibility: visible;
}

.text-container-cap:active .tooltiptext-cap {
    visibility: visible;
}