.icon {
    padding: 5px;
}

.disabledIcon {
    filter: invert(47%) sepia(25%) saturate(438%) hue-rotate(176deg) brightness(92%) contrast(85%);
    padding: 5px;
}

.buttonWithIcon {
    min-width: 320px;
    min-height: 40px;
    border-radius: 15px;    
}

.cardButton{
    min-width: 315px;
    min-height: 32px;
    border-radius: 15px;
    margin-top: 10px;
}